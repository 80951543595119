import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";
import AddFeaturesCard from "./add-features-card/add-features-card";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import "./add-features.component.scss";
import ButtonComponent from "components/shared/button/button.component";
import {ArrowRightSymbols} from "../../assets/icons";
import {AssetFeatureData, DerivedFeatureData, FormulaType, MetricType} from "./feature-types";
import {useParams} from "react-router-dom";
import {useFirstLast} from "../../context/first-last-feature-context";
import {deleteAllFirstLastFeatures, deleteFirstLastFeature} from "../../actions/first-last-reducer";
import { saveFeatures } from "services/entities";
import { useGlobalContext } from "context/global-context";
import { FeatureType, featureTypeToLabel } from "types/features";
import AssetTitle from "../shared/assets/asset-title/asset-title.component";
import dataAsset from "../../page/data-asset/data-asset";

interface AddFeaturesComponentProps {
    handleClickFeatureClose: (shouldRefresh?: boolean) => void;
}

const AddFeaturesComponent: React.FC<AddFeaturesComponentProps> = ({handleClickFeatureClose}) => {
    const {entityId} = useParams<{ entityId: string }>();
    const {selectedGitBranch} = useGlobalContext();
    const [rows, setRows] = React.useState<(AssetFeatureData | DerivedFeatureData)[]>([]);
    const {firstLastFeatures, dispatchFirstLastAction} = useFirstLast();
    const [metricFeatures, setMetricFeatures] = useState<MetricType[]>([]);
    const [formulaFeatures, setFormulaFeatures] = useState<FormulaType[]>([]);

    const deleteMetricFeature = (id: AssetFeatureData["id"]) => {
        const filteredFeatures: any[] = [];
        for (const metric of metricFeatures) {
            if (metric?.measures) {
                const measures = metric?.measures.filter((m) => ![m.name, m.alias].includes(id));
                if (measures.length) {
                    filteredFeatures.push({...metric, measures});
                }
            }
        }
        setMetricFeatures(filteredFeatures);
    };

    const deleteFormulaFeature = (id: AssetFeatureData["id"]) => {
        setFormulaFeatures(prevState => prevState.filter(f => f.name !== id));
    };

    const handleDelete = (id: AssetFeatureData["id"], type: FeatureType) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        if (type === FeatureType.firstLast) dispatchFirstLastAction(deleteFirstLastFeature(id));
        if (type === FeatureType.metric) deleteMetricFeature(id);
        if (type === FeatureType.formula) deleteFormulaFeature(id);
    };


    const columns: GridColDef[] = [
        {
            field: "type",
            headerName: "Type",
            sortable: false,
            renderCell: (params) => (
                <Typography className="flex-box-align-center" sx={{color: theme.palette.customColor.purple}}>
                    <span className="material-symbols-outlined"
                          style={{fontSize: "20px", color: theme.palette.customColor.purple}}>
                        analytics
                    </span>
                    {featureTypeToLabel(params.value)}
                </Typography>
            ),
        },
        {field: "featureName", headerName: "Name", flex: 1, sortable: true},
        {field: "dataAssetId",
            headerName: "Origin data asset",
            flex: 1,
            sortable: false,
            renderCell: ({row}) => (
                <AssetTitle
                    assetId={row.dataAssetId}
                />
            ),
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDelete(params.row.id, params.row.type)}
                >
                    delete
                </span>
            ),
        },
    ];

    const onExit = (shouldRefresh?: boolean) => {
        dispatchFirstLastAction(deleteAllFirstLastFeatures());
        handleClickFeatureClose(shouldRefresh);
    };

    useEffect(() => {
        if (Object.values(firstLastFeatures).length > 0) {
            const newRows: AssetFeatureData[] = [];
            for (const feature of firstLastFeatures) {
                feature.fields.forEach(field=> {
                    const featureName = `${field.alias || field.name}`;
                    const row = {
                        id: `${feature.id as string}-${featureName}`,
                        featureName: `${field.alias || field.name}`,
                        dataAssetId: feature.dataAssetId,
                        type: FeatureType.firstLast,
                        feature: {...feature}
                    };
                    row.feature.fields = [field];
                    newRows.push(row)
                })
            }
            if (newRows) setRows(newRows);
        }
    }, [firstLastFeatures]);

    const handleFeatureAdded = (type: FeatureType, feature: MetricType | FormulaType) => {
        // TODO Olha: replace with Metric context
        let newRows: (AssetFeatureData | DerivedFeatureData)[] = []
        switch (type){
            case FeatureType.metric:
                const metricFeature = feature as MetricType
                newRows = ((metricFeature.measures || []).map(measure => ({
                    id: measure.name,
                    featureName: measure.alias || measure.name,
                    dataAssetId: metricFeature.dataAssetId,
                    type: FeatureType.metric,
                    feature: {...feature, measures:[measure]}
                })) as AssetFeatureData[]);
                setMetricFeatures((prevState: MetricType[]) => [...prevState, metricFeature]);
                break;
            case FeatureType.formula:
                const formulaFeature = feature as FormulaType
                newRows = [{
                    id: formulaFeature.name,
                    featureName: formulaFeature.name,
                    type: FeatureType.formula,
                    feature: formulaFeature
                }] as DerivedFeatureData[]
                setFormulaFeatures((prevState: FormulaType[]) => [...prevState, formulaFeature]);
                break;
        }
        if (newRows.length) setRows((prevRows) => [...prevRows, ...newRows]);
    };

    const handleNext = async () => {
        await saveFeatures(
            (entityId as string),
            (selectedGitBranch as string),
            firstLastFeatures,
            metricFeatures,
            formulaFeatures
        );
        onExit(true);
    };

    return (
        <Box className="flex-box-col-center"
             sx={{width: "90%", margin: "0 auto", overflow: "hidden"}}>
            <Box className="flex-box-align-center flex-box-end" sx={{width: "100%", marginTop: "24px"}}>
                <ButtonComponent
                    variant="outlined"
                    label="Exit"
                    onClick={()=>onExit()}
                    sx={{marginRight: "16px"}}
                />

                <ButtonComponent
                    variant="contained"
                    endIcon={<ArrowRightSymbols/>}
                    label="Add features"
                    onClick={handleNext}
                    disabled={!Boolean(rows?.length)}
                />
            </Box>
            <Typography
                variant="h3"
                sx={{
                    fontSize: "42px",
                    color: theme.palette.customColor.dark,
                    lineHeight: "46px",
                    letterSpacing: "-0.84px",
                    margin: "50px 0 24px 0",
                }}
            >
                Add features
            </Typography>
            <Typography variant="subtitle2" sx={{fontWeight: 400, color: theme.palette.customColor.grey}}>
                Add features to&nbsp;
                <Typography component="span"
                            sx={{fontSize: "16px", fontWeight: 500, color: theme.palette.customColor.dark}}>
                    {entityId}
                </Typography>
            </Typography>

            <Box>
                <AddFeaturesCard featuresAdded={rows.length > 0} onFeatureSaved={handleFeatureAdded}/>
            </Box>

            {rows.length > 0 && (
                <Box className="feature-table" sx={{maxWidth: "1232px", width: "100%"}}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.palette.customColor.black,
                            fontWeight: 600,
                            marginBottom: "16px",
                        }}
                    >
                        Added features
                    </Typography>
                    <DataGrid
                        hideFooter={true}
                        rows={rows}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        className="entity-data-grid feature-data-grid"
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                        autoHeight
                    />
                </Box>
            )}
        </Box>
    );
};

export default AddFeaturesComponent;
