import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import theme from 'theme';
import './asset-title.component.scss';

interface AssetTitleProps {
    assetId: string;
}

const AssetTitle: React.FC<AssetTitleProps> = ({ assetId }) => {
    const [db, schema, tableName] = assetId.split(".");

    return (
        <Box className="flex-box-start-center">
            <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.black, marginRight: "16px" }}>
                {tableName || ''}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
                <Chip
                    className="asset-title-chip"
                    label={`${db}.${schema}`}
                    color="primary"
                    variant="outlined"
                />
            </Stack>
        </Box>
    );
};

export default AssetTitle;