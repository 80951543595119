import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ArrowLeftSymbols } from "assets/icons";
import ButtonComponent from "components/shared/button/button.component";
import { DataAssetFullResponse, DataAssetResponse, getDataAssetById } from "services/data-assets";
import { DataAssetName, getDataAssetTitle } from "../../data-asset-name";
import theme from "theme";
import "./add-features-modal-data.scss";
import { MetricType } from "../../feature-types";
import AddFeaturesMetricModalContent from "./add-features-metric-modal-content";
import SqlView from "components/shared/sql-view/sql-view.component";
import { MetricTemplatePreviewOptions, TemplateType, preview } from "services/templates";
import { useGlobalContext } from "context/global-context";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

interface AddFeaturesModalDataProps {
    dataAssets: DataAssetResponse[];
    selectedAsset: DataAssetFullResponse | null;
    setSelectedAsset: (asset: DataAssetFullResponse | null) => void;
    openMeasuresModal: boolean;
    setOpenMeasuresModal: (open: boolean) => void;
    handleCloseModal: () => void;
    isLoading: boolean;
    errorMessage: string | null;
    onMetricSaved: (metric: MetricType) => void;
}

const AddFeaturesMetricModalData: React.FC<AddFeaturesModalDataProps> = ({
                                                                             dataAssets,
                                                                             selectedAsset,
                                                                             setSelectedAsset,
                                                                             openMeasuresModal,
                                                                             setOpenMeasuresModal,
                                                                             handleCloseModal,
                                                                             isLoading,
                                                                             errorMessage,
                                                                             onMetricSaved
                                                                         }) => {
    const { entityId } = useParams<{ entityId: string }>();
    const [isCreateMetricEnabled, setCreateMetricEnabled] = useState(false);
    const [metricDraft, setMetricDraft] = useState<MetricType>();
    const { selectedGitBranch } = useGlobalContext();
    const [sql, setSql] = useState<string>("");

    const fetchDataAsset = async (assetId: string) => {
        try {
            const response = await getDataAssetById(assetId, selectedGitBranch);
            setSelectedAsset(response);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleOpen = (assetId: string) => {
        fetchDataAsset(assetId);
    };

    const handleBack = () => {
        setSelectedAsset(null);
        setCreateMetricEnabled(false);
    };

    const updateSql = async (metric: MetricType) => {
        const previewOptions: MetricTemplatePreviewOptions = {
            entity_id: entityId as string,
            asset_id: metric.dataAssetId,
            filters: (metric.filters || []).map(f => ({
                field: f.name,
                operator: f.operator,
                values: f.values,
                data_type: f.dataType,
            })),
            features: (metric.measures || []).map(measure => ({
                sql: measure.definition,
                name: measure.alias || measure.name,
            })),
        };

        const { sql: newSql } = await preview(selectedGitBranch, TemplateType.metric, previewOptions);
        setSql(newSql);
    };

    const debouncedUpdateSql = useDebouncedCallback(updateSql, 300);

    const handleMetricUpdated = async (metric: MetricType): Promise<void> => {
        setCreateMetricEnabled(Boolean(metric.measures?.length));
        setMetricDraft(metric);
        debouncedUpdateSql(metric);
    };

    const saveMetric = () => {
        if (metricDraft) onMetricSaved(metricDraft);
        handleCloseModal();
    };

    if (errorMessage && !isLoading) {
        return <Typography color="error" variant="body2" sx={{ margin: "auto" }}>{errorMessage}</Typography>;
    }

    if (selectedAsset && !isLoading) {
        return (
            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                <Box sx={{ width: "450px", flexShrink: 0 }}>
                    <SqlView sql={sql} />
                </Box>
                <Box className="flex-box-col" sx={{ width: "100%" }}>
                    <DataAssetName asset={selectedAsset} />

                    <ButtonComponent
                        className="select-button-back"
                        variant="text"
                        label={<ArrowLeftSymbols />}
                        onClick={handleBack}
                    />

                    <AddFeaturesMetricModalContent
                        selectedAsset={selectedAsset}
                        metricDraft={metricDraft}
                        onMetricUpdated={handleMetricUpdated}
                    />

                    <Box
                        className="flex-box-end"
                        position="sticky"
                        bottom="0px"
                        sx={{ background: theme.palette.customColor.white }}
                    >
                        <ButtonComponent variant="text" label="Cancel" onClick={handleCloseModal} />
                        <ButtonComponent
                            disabled={!isCreateMetricEnabled}
                            variant="contained"
                            label="Create metric"
                            onClick={saveMetric}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            className={!dataAssets.length ? "flex-box-col-center" : ""}
            sx={{ width: "100%", height: "100%", overflowY: "scroll" }}
        >
            {isLoading ? (
                <CircularProgress sx={{ fontColor: theme.palette.customColor.grey, margin: "auto" }} />
            ) : (
                dataAssets.length ? (
                    dataAssets.map((asset, index) => (
                        <Box
                            className="modal-data flex-box-align-center"
                            key={index}
                            onClick={() => handleOpen(asset.id)}
                            sx={{ cursor: "pointer" }}
                        >
                            <span className="material-symbols-outlined modal-icon-data">database</span>
                            {getDataAssetTitle(asset)}
                        </Box>
                    ))
                ) : (
                    <Typography variant="subtitle2">
                        No data assets found
                    </Typography>
                )
            )}
        </Box>
    );
};

export default AddFeaturesMetricModalData;