import theme from "theme";
import {Box, Typography} from "@mui/material";
import React from "react";
import {DataAssetFullResponse, DataAssetResponse} from "services/data-assets";
import AssetTitle from "../shared/assets/asset-title/asset-title.component";

export const getDataAssetName = (asset: DataAssetResponse | DataAssetFullResponse) =>
    `${asset["db"]}.${asset["schema"]}.${asset["name"]}`.toLowerCase();

export const getDataAssetTitle = (asset: DataAssetResponse | DataAssetFullResponse) =>
    <AssetTitle assetId={getDataAssetName(asset)} />;

interface DataAssetNameProps {
    asset: DataAssetResponse | DataAssetFullResponse;
}

export const DataAssetName: React.FC<DataAssetNameProps> = ({asset}) => {
    return (
        <>
            <Typography variant="subtitle2" sx={{color: theme.palette.customColor.darkGrey}}>
                Selected Data Assets
            </Typography>
            <Box className="flex-box-align-center select-text-step" sx={{mt: "10px"}}>
                <span className="material-symbols-outlined modal-icon-data">
                    database
                </span>
                {getDataAssetTitle(asset)}
            </Box>
        </>
    )
}