import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import AddFeaturesTitleSearch from "./add-features-modal-content/add-features-title-search/add-features-title-search";
import {
    DataAssetFullResponse,
    DataAssetResponse,
    DataAssetsPaginationResponse,
    getDataAssets,
    PaginationParams
} from "services/data-assets";
import AddFeaturesMetricModalData from "./add-features-modal-content/add-features-modal-data/add-features-metric-modal-data";
import AddFeaturesFirstLastModalData from "./add-features-modal-content/add-features-modal-data/add-features-first-last-modal-data";
import { FeatureModelType, MetricType } from "./feature-types";

interface AddFeaturesMetricProps {
    type: FeatureModelType;
    handleCloseModal: () => void;
    onFeatureSaved: (feature: MetricType) => void;
}

const AddFeaturesModal: React.FC<AddFeaturesMetricProps> = ({ type, handleCloseModal, onFeatureSaved }) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedAsset, setSelectedAsset] = useState<DataAssetFullResponse | null>(null);
    const [openMeasuresModal, setOpenMeasuresModal] = useState<boolean>(false);
    const [filteredAssets, setFilteredAssets] = useState<DataAssetResponse[]>([]); // Use DataAssetResponse[]
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchDataAssets = async (tableId: string) => {
        setErrorMessage(null);
        setIsLoading(true);
        try {
            const params: PaginationParams = tableId.length ? { tableId } : {};
            const response: DataAssetsPaginationResponse = await getDataAssets(params);
            const assets = response["assets"];
            setFilteredAssets(assets); // Store the full DataAssetResponse objects
        } catch (error: any) {
            setErrorMessage(error?.message);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDataAssets(searchValue);
    }, [searchValue]);

    return (
        <Box className="flex-box-col-start" sx={{ gap: "2px", height: "100%" }}>
            <AddFeaturesTitleSearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                selectedAsset={selectedAsset}
                title={`Add ${type}`}
            />
            {type === "metric" && (
                <AddFeaturesMetricModalData
                    dataAssets={filteredAssets}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    openMeasuresModal={openMeasuresModal}
                    setOpenMeasuresModal={setOpenMeasuresModal}
                    handleCloseModal={handleCloseModal}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    onMetricSaved={onFeatureSaved}
                />
            )}
            {type === "first/last" && (
                <AddFeaturesFirstLastModalData
                    dataAssets={filteredAssets}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    handleCloseModal={handleCloseModal}
                />
            )}
        </Box>
    );
};

export default AddFeaturesModal;