import React, {useState} from "react";
import {Box} from "@mui/material";
import {ArrowLeftSymbols} from "assets/icons";
import "./add-features-modal-data.scss";
import ButtonComponent from "components/shared/button/button.component";
import AddFeaturesFirstLastModalContent from "./add-features-first-last-modal-content";
import {DataAssetFullResponse, DataAssetResponse, getDataAssetById} from "services/data-assets";
import {TemplateType, preview} from "services/templates";
import {DataAssetName, getDataAssetTitle} from "../../data-asset-name";
import { FirstLastType } from "components/add-features-component/feature-types";
import { useGlobalContext } from "context/global-context";
import { useParams } from "react-router-dom";
import SqlView from "components/shared/sql-view/sql-view.component";
import { useDebouncedCallback } from "use-debounce";

interface AddFeaturesModalDataProps {
    dataAssets: DataAssetResponse[];
    selectedAsset: DataAssetFullResponse | null;
    setSelectedAsset: (asset: DataAssetFullResponse | null) => void;
    handleCloseModal: () => void;
}

const AddFeaturesFirstLastModalData: React.FC<AddFeaturesModalDataProps> = ({
                                                                                dataAssets,
                                                                                selectedAsset,
                                                                                setSelectedAsset,
                                                                                handleCloseModal,
}) => {
    // TODO: this should be changed to be taken from the main dialog context instead from route
    const {entityId} = useParams<{ entityId: string }>();
    const {selectedGitBranch} = useGlobalContext();
    const [sql, setSql] = useState<string>("");

    const fetchDataAsset = async (assetId: string) => {
        try {
            const response = await getDataAssetById(assetId, selectedGitBranch);
            setSelectedAsset(response);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleOpen = (assetId: string) => {
        fetchDataAsset(assetId);
    };

    const onDataChanged = async (data: FirstLastType) => {
        const { sql: newSql } = await preview(selectedGitBranch, TemplateType.firstLast, {
            entity_id: entityId as string,
            asset_id: data.dataAssetId,
            filters: (data.filters || []).map(f=>({
                field: f.name,
                operator: f.operator,
                values: f.values,
                data_type: f.dataType
            })),
            method: data.method || "first",
            sort_by: data.sortBy || "",
            fields: data.fields.map(field=>({field: field.name, name: field.alias || field.name}))
        })
        setSql(newSql)
    }
    const debouncedOnDataChanged = useDebouncedCallback(onDataChanged, 300);

    return (
        <Box sx={{width: "100%", overflow: "hidden"}}>
            {!selectedAsset ? (
                <Box sx={{width: "100%", height: "100%", overflowY: "scroll"}}>
                    {dataAssets.map((asset, index) => (
                        <Box
                            className="modal-data flex-box-align-center"
                            key={index}
                            onClick={() => handleOpen(asset.id)}
                            sx={{cursor: "pointer"}}
                        >
                            <span className="material-symbols-outlined modal-icon-data">database</span>
                            {getDataAssetTitle(asset)}
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box sx={{display:"flex", height: "100%"}}>
                    <Box sx={{width: "450px", flexShrink: 0}}>
                        <SqlView sql={sql} />
                    </Box>
                    <Box sx={{flexGrow: 1, overflow: "auto"}}>
                        {selectedAsset && <DataAssetName asset={selectedAsset}/>}

                        <ButtonComponent
                            className="select-button-back"
                            variant="text"
                            label={<ArrowLeftSymbols/>}
                            onClick={() => setSelectedAsset(null)}
                        />

                        <AddFeaturesFirstLastModalContent
                            selectedAsset={selectedAsset}
                            onChange={debouncedOnDataChanged}
                            onClose={handleCloseModal}
                        />

                    </Box>
                </Box>

            )}
        </Box>
    );
};

export default AddFeaturesFirstLastModalData;